import { httpPost } from './http'
// 删除订单
export function delOrder(params) {
    return httpPost('/center/delOrder', params)
}
// 获取订单详情
export function getOrderDetail(params) {
    return httpPost('/airTicket/toOrderDetailQuery', params)
}
// 退票申请
export function tROrderSave(params) {
    return httpPost('/airItr/tROrderSave', params)
}
// 改签申请
export function tCOrderSave(params) {
    return httpPost('/airOrder/tCOrderSave', params)
}
// 改签航班查询
export function tCDomesticTicketShopping(params) {
    return httpPost('/airOrder/tCDomesticTicketShopping', params)
}
// 退票报价（国内）
export function trPricingV2(params) {
    return httpPost('/airItr/trPricingV2', params)
}
//取消订单
export function cancelOrder(params) {
    return httpPost('/center/cancel', params)
}
// 获取改签订单列表
export function getChangeOrderList(params) {
    return httpPost('/airOrder/orders', params)
}

//修改订单状态
export function setStatus(params) {
    return httpPost('/master/setStatus', params)
}

//支付订单回传
export function setPayNo(params) {
    return httpPost('/master/setPayNo', params)
}

//手动建单
export function createOrder(params) {
    return httpPost('/master/createOrder', params)
}

