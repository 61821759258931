<template>
  <div class="flight-refund-page">
    <div class="page-container">
      <!-- 左侧主要内容 -->
      <div class="main-content">
        <div class="page-header">
          <h2>退票申请</h2>
          <div class="notice">
            <i class="el-icon-info"></i>
            退票需缴纳手续费
          </div>
        </div>

        <!-- 乘客/航段选择 -->
        <div class="section passenger-info">
          <div class="section-title">1. 选择需要取消的机票</div>
          <div class="passenger-list">
            <el-checkbox-group v-model="selectedPassengers">
              <div class="passenger-item" v-for="passenger in flightInfo?.passengerList" :key="passenger.passengerID">
                <el-checkbox :label="passenger.passengerID">
                  <div class="passenger-info">
                    <span class="name">{{ passenger.sur }}/{{ passenger.name }}</span>
                    <span class="type">{{ getPassengerType(passenger.passengerType) }}</span>
                  </div>
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </div>
          <div class="flight-segment">
            <div class="route">
              {{ flightInfo?.flightList[0]?.departureCityName }} - {{ flightInfo?.flightList[0]?.destinationCityName }}
            </div>
            <div class="time">
              {{ formatDate(flightInfo?.flightList[0]?.departTime) }} {{ formatTime(flightInfo?.flightList[0]?.departTime) }}
            </div>
          </div>
        </div>

        <!-- 退票金额预估 -->
        <div class="section refund-estimate">
          <div class="section-title">2. 退票金额预估</div>
          <div class="amount-info">
            <div class="amount-row">
              <span class="label">已付金额</span>
              <span class="value">{{currencyInfo.currency_symbol}}  {{ (selectedTotalAmount  * currencyInfo.exchange_rate ).toFixed(2)|| '--' }}</span>
            </div>
            <div class="amount-row">
              <span class="label">退票手续费</span>
              <span class="value">{{currencyInfo.currency_symbol}}  {{ (selectedRefundFee  * currencyInfo.exchange_rate ).toFixed(2) || '--' }}</span>
            </div>
            <div class="amount-row total">
              <span class="label">预计退款金额</span>
              <span class="value">{{currencyInfo.currency_symbol}}  {{ (finalRefundAmount  * currencyInfo.exchange_rate ).toFixed(2) }}</span>
            </div>
          </div>
        </div>

        <!-- 退款方式 -->
        <div class="section refund-method">
          <div class="section-title">3. 退款方式</div>
          <div class="method-info">
            <p>将原路退回至您的支付账户</p>
          </div>
        </div>

        <!-- 提交按钮 -->
        <div class="submit-section">
          <el-button type="primary" @click="submitRefund" :loading="submitting">提交</el-button>
        </div>
      </div>

      <!-- 右侧退票政策 -->
      <div class="side-content">
        <div class="policy-card">
          <div class="card-header">
            <h3>退款信息</h3>
          </div>
          <div class="policy-content">
            <div class="refund-amount">
              <div class="amount-item">
                <span class="label">已付金额</span>
                <span class="value">{{currencyInfo.currency_symbol}}  {{ (selectedTotalAmount  * currencyInfo.exchange_rate ).toFixed(2)|| '--' }}</span>
              </div>
              <div class="amount-item">
                <span class="label">退票手续费</span>
                <span class="value">{{currencyInfo.currency_symbol}}  {{ (selectedRefundFee  * currencyInfo.exchange_rate ).toFixed(2) || '--' }}</span>
              </div>
              <div class="amount-item total">
                <span class="label">预计退款金额</span>
                <span class="value">{{currencyInfo.currency_symbol}}  {{ (finalRefundAmount  * currencyInfo.exchange_rate ).toFixed(2) }}</span>
              </div>
            </div>
            <div class="notice">
              <i class="el-icon-warning-outline"></i>
              <span>退票政策或因航空公司调整有所变更，请以实际退票费用为准。</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getOrderDetail, tROrderSave,trPricingV2 } from '@/api/order'
import { mapState } from 'vuex'

export default {
  name: 'FlightRefund',
  data() {
    return {
      flightInfo: null,
      selectedPassengers: null,
      submitting: false,
      refundPricing: {
        workTime: {
          begin: '',
          end: ''
        },
        serialNumber: '', // 报价key
        fees: [] // 退票费用明细
      }
    }
  },
  computed: {
    ...mapState(['currencyInfo']),
    // 计算选中乘客的总票价
    selectedTotalAmount() {
      // 如果有退票报价信息，计算所有乘客的原单退款金额总和
      if (this.refundPricing.fees.length) {
        return this.refundPricing.fees.reduce((sum, fee) => sum + (fee.toPrice || 0), 0)
      }
      // 否则使用订单中的金额
      if (!this.flightInfo?.financeDetail?.financeList || !this.selectedPassengers?.length) return 0
      const perPersonAmount = this.flightInfo.financeDetail.financeList[0]?.saleTotal || 0
      return perPersonAmount * this.selectedPassengers.length
    },
    // 计算选中乘客的退票手续费
    selectedRefundFee() {
      // 如果有退票报价信息，计算所有乘客的退票手续费总和
      if (this.refundPricing.fees.length) {
        return this.refundPricing.fees.reduce((sum, fee) => sum + (fee.returnFee || 0), 0)
      }
      // 否则使用默认手续费
      if (!this.selectedPassengers?.length) return 0
      const feePerPerson = this.flightInfo?.RCVServiceFee?.RefundFeeCNY || 0
      return feePerPerson * this.selectedPassengers.length
    },
    // 计算最终退款金额
    finalRefundAmount() {
      // 如果有退票报价信息，计算所有乘客的合计金额总和
      if (this.refundPricing.fees.length) {
        return this.refundPricing.fees.reduce((sum, fee) => sum + (fee.saleTotal || 0), 0).toFixed(2)
      }
      return (this.selectedTotalAmount - this.selectedRefundFee).toFixed(2)
    }
  },
  created() {
    this.loadOrderDetail()
  },
  methods: {
    async loadOrderDetail() {
      try {
        console.log('开始获取订单详情，订单号:', this.$route.query.id)
        const res = await getOrderDetail({
          QDOrderID: this.$route.query.id
        })
        if (res.code === 1) {
          this.flightInfo = res.data
          // 检查是否有乘客和金额信息
          if (!this.flightInfo.financeDetail?.financeList?.length) {
            this.$message.error('获取信息失败')
            return
          }

          // 先设置选中的乘客
          if (this.flightInfo.passengerList?.length) {
            this.selectedPassengers = this.flightInfo.passengerList.map(p => p.passengerID)
          } else {
            this.$message.error('没有乘客信息')
            return
          }

          if (this.flightInfo.orderInfo.productSubType === 0) {
            // 国内订单
            const params = {
              TOOrderID: this.flightInfo.orderInfo.orderID,
              FlightList: this.flightInfo.flightList.map(flight => ({
                TOFlightID: flight.flightID.toString()
              })),
              PassengerList: this.selectedPassengers.map(id => ({
                TOPassengerID: id.toString()
              })),
              TRReason: 0 // 退票原因
            }
            const res = await trPricingV2(params)
            if (res.code === 1) {
              // 保存退票报价信息
              this.refundPricing = {
                workTime: {
                  begin: res.data.workBeginTime,
                  end: res.data.workEndTime
                },
                serialNumber: res.data.serialNumber,
                fees: res.data.trFeeList
              }
              // 添加工作时间提示
              if (this.refundPricing.workTime.begin && this.refundPricing.workTime.end) {
                this.$message.info(`退票处理时间: ${this.refundPricing.workTime.begin} - ${this.refundPricing.workTime.end}`)
                }
            }
          }
        } else {
          throw new Error(res.info || '获取订单详情失败')
        }
      } catch (error) {
        console.error('获取订单信息失败:', error)
        this.$message.error(error.message || '获取订单信息失败')
      }
    },
    formatDate(date) {
      if (!date) return '--'
      const d = new Date(date)
      return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`
    },
    formatTime(time) {
      if (!time) return '--'
      const date = new Date(time)
      return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`
    },
    getPassengerType(type) {
      const types = {
        0: '成人',
        1: '儿童',
        2: '婴儿'
      }
      return types[type] || '成人'
    },
    async submitRefund() {
      if (!this.selectedPassengers?.length) {
        this.$message.warning('请选择需要退票的乘客')
        return
      }
      try {
        this.submitting = true
        let Refundres
        if (this.flightInfo.orderInfo.productSubType === 0) {
          // 国内订单
          const params = {
            serialNumber: this.refundPricing.serialNumber,
            TOOrderID: this.flightInfo.orderInfo.orderID,
            QDOrderID: this.flightInfo.QDOrderID,
            PassengerList: this.selectedPassengers.map(id => ({
              TOPassengerID: id.toString()
            })),
            FlightList: this.flightInfo.flightList.map(flight => ({
              TOFlightID: flight.flightID.toString()
            })),
            TROperation: 3,
            TRReason: 0
          }
          console.log('退票提交参数:', params)
          // 调用退票接口
          Refundres = await tROrderSave(params)
        } else {
          // 国际订单
          const params = {
            TOOrderID: this.flightInfo.orderInfo.orderID,
            QDOrderID: this.flightInfo.QDOrderID,
            PassengerList: this.selectedPassengers.map(id => ({
              TOPassengerID: id.toString()
            })),
            FlightList: this.flightInfo.flightList.map(flight => ({
              TOFlightID: flight.flightID.toString()
            })),
            TROperation: 3, // 退票操作
            TRReason: 0 // 退票原因
          }
          // 调用退票接口
          Refundres = await tROrderSave(params)
        }
        
        if (Refundres.code === 1) {
          this.$message.success('退票申请已提交')
          // 跳转到订单列表页
          this.$router.push('/orders')
        } else {
          throw new Error(Refundres.info || '退票申请失败')
        }
      } catch (error) {
        if (error !== 'cancel') {
          this.$message.error(error.message || '提交退票申请失败')
        }
      } finally {
        this.submitting = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.flight-refund-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 60px;

  .page-container {
    display: flex;
    gap: 24px;
  }

  .main-content {
    flex: 1;

    .page-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;

      h2 {
        font-size: 20px;
        font-weight: 500;
        color: #303133;
        margin: 0;
      }

      .notice {
        color: #E6A23C;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }

    .section {
      background: #fff;
      padding: 24px;
      border-radius: 8px;
      box-shadow: 0 2px 12px 0 rgba(0,0,0,.05);
      margin-bottom: 24px;

      .section-title {
        font-size: 16px;
        font-weight: 500;
        color: #303133;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        
        &:before {
          content: '';
          width: 4px;
          height: 16px;
          background: #409EFF;
          margin-right: 8px;
          border-radius: 2px;
        }
      }
    }

    .passenger-list {
      margin-bottom: 20px;

      .passenger-item {
        padding: 12px 16px;
        background: #F8F9FA;
        border-radius: 4px;
        margin-bottom: 12px;
        
        &:last-child {
          margin-bottom: 0;
        }

        .el-checkbox {
          display: flex;
          align-items: center;
          width: 100%;
          
          .passenger-info {
            margin-left: 8px;
            display: flex;
            align-items: center;
            gap: 12px;
            
            .name {
              font-size: 14px;
              font-weight: 500;
              color: #303133;
            }
            
            .type {
              font-size: 13px;
              color: #909399;
              background: #F0F2F5;
              padding: 2px 8px;
              border-radius: 10px;
            }
          }
        }
      }
    }

    .flight-segment {
      background: #F8F9FA;
      padding: 16px;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .route {
        font-size: 15px;
        font-weight: 500;
      }

      .time {
        color: #606266;
      }
    }

    .amount-info {
      .amount-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 16px;
        background: #F8F9FA;
        border-radius: 4px;
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }

        &.total {
          background: #fff;
          border: 1px solid #DCDFE6;
          margin-top: 20px;
          font-size: 16px;
          font-weight: 500;

          .value {
            color: #F56C6C;
          }
        }

        .label {
          color: #606266;
        }

        .value {
          font-weight: 500;
          color: #303133;
        }
      }
    }

    .method-info {
      padding: 16px;
      background: #F8F9FA;
      border-radius: 4px;
      color: #606266;
    }

    .submit-section {
      text-align: center;
      padding-top: 24px;
      border-top: 1px solid #EBEEF5;

      .el-button {
        padding: 12px 36px;
        font-size: 15px;    
        width: 100%;
      }
    }
  }

  .side-content {
    width: 300px;

    .policy-card {
      position: sticky;
      top: 80px;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 12px 0 rgba(0,0,0,.05);

      .card-header {
        padding: 16px 20px;
        border-bottom: 1px solid #EBEEF5;

        h3 {
          margin: 0;
          font-size: 16px;
          font-weight: 500;
          color: #303133;
        }
      }

      .policy-content {
        padding: 20px;

        .refund-amount {
          margin-bottom: 16px;

          .amount-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 0;
            
            &:not(:last-child) {
              border-bottom: 1px dashed #EBEEF5;
            }
            
            &.total {
              margin-top: 12px;
              padding-top: 16px;
              border-top: 1px solid #EBEEF5;
              border-bottom: none;
              
              .label {
                font-size: 15px;
                font-weight: 500;
              }
              
              .value {
                font-size: 18px;
                color: #F56C6C;
              }
            }

            .label {
              color: #606266;
            }

            .value {
              font-weight: 500;
              color: #303133;
            }
          }
        }

        .notice {
          padding: 12px;
          background: #FDF6EC;
          border-radius: 4px;
          display: flex;
          align-items: flex-start;
          gap: 8px;
          font-size: 13px;
          color: #E6A23C;

          i {
            margin-top: 2px;
          }
        }
      }
    }
  }
}
</style> 