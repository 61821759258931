import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login.vue'
import Register from '@/views/Register.vue'
import Refund from '@/views/flight/Refund'
import ManualOrder from '@/views/ManualOrder.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/flight'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: '登录'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      title: '注册'
    }
  },
  {
    path: '/flight',
    name: 'Flight',
    component: () => import('../views/Flightnew.vue')
  },
  {
    path: '/flight/result',
    name: 'FlightResult',
    component: () => import('@/views/flight/Result.vue')
  },
  {
    path: '/flight/resultnew',
    name: 'FlightResultNew',
    component: () => import('@/views/flight/Resultnew.vue')
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('@/views/Orders.vue'),
    meta: {
      requiresAuth: true,
      title: '我的订单old'
    }
  },{
    path: '/OrderNew',
    name: 'OrderNew',
    component: () => import('@/views/OrderNew.vue'),
    meta: {
      requiresAuth: true,
      title: '我的订单'
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      title: '找回密码'
    }
  },
  {
    path: '/flight/domestic-order',
    component: () => import('@/views/DomesticFlightOrder.vue')
  },
  {
    path: '/order/detail/:id',
    name: 'OrderDetail',
    component: () => import('@/views/order/Detail.vue'),
    meta: {
      requiresAuth: true,
      title: '订单详情'
    }
  },
  {
    path: '/flight/status',
    name: 'FlightStatus',
    component: () => import('@/views/flight/Status.vue'),
    meta: {
      title: '航班状态'
    }
  },
  {
    path: '/flight/change',
    name: 'FlightChange',
    component: () => import('@/views/flight/Change.vue'),
    meta: {
      title: '航班改签'
    }
  },
  {
    path: '/flight/refund',
    name: 'FlightRefund',
    component: Refund
  },
  {
    path: '/manual-order',
    name: 'ManualOrder',
    component: ManualOrder,
    meta: {
      requiresAuth: true,
      title: '手动建单'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router 