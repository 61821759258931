<template>
  <header class="header">
    <!-- 顶部导航条 -->
    <div class="header-top">
      <div class="container">
        <div class="top-links">
          
        </div>
        <div class="user-actions">
          <el-dropdown trigger="click" class="lang-dropdown">
            <span class="el-dropdown-link">
              <i class="el-icon-globe"></i>
              中文(简体) <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>English</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <!-- 币种选择器 -->
          <el-dropdown 
            trigger="click" 
            class="currency-dropdown"
            @command="handleCurrencyChange">
            <span class="el-dropdown-link">
              <i class="el-icon-money"></i>
              {{ currentCurrencyName }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item 
                v-for="currency in currencies" 
                :key="currency.id"
                :class="{ active: isActiveCurrency(currency) }"
                :command="currency.currency_standard_symbol">
                <i class="el-icon-check" v-if="isActiveCurrency(currency)"></i>
                {{ currency.currency_name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <!-- 未登录状态 -->
          <template v-if="!userInfo">
            <router-link to="/login" class="action-link">
              <i class="el-icon-user"></i> 登录
            </router-link>
            <div class="divider"></div>
            <!-- <router-link to="/register" class="action-link">注册</router-link> -->
          </template>

          <!-- 登录状态 -->
          <template v-else>
            <el-dropdown @command="handleCommand" trigger="click" class="user-dropdown">
              <span class="user-info">
                <span class="avatar">{{ userInfo.phone?.charAt(0)?.toUpperCase() || 'U' }}</span>
                {{ userInfo.phone }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="profile">
                  <i class="el-icon-user"></i> 个人中心
                </el-dropdown-item>
                <el-dropdown-item command="orders">
                  <i class="el-icon-tickets"></i> 我的订单
                </el-dropdown-item>
                <el-dropdown-item divided command="logout">
                  <i class="el-icon-switch-button"></i> 退出登录
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <div class="divider"></div>
          <router-link to="/OrderNew" class="action-link highlight">
            <i class="el-icon-s-order"></i> 订单
          </router-link>
          <div class="divider"></div>
          <a href="#" class="action-link">
            <i class="el-icon-question"></i> 帮助中心
          </a>
        </div>
      </div>
    </div>

    <!-- 主导航栏 -->
    <nav class="nav-bar">
      <div class="container">
        <div class="logo">
          <router-link to="/">
            <img src="@/assets/images/logo.png" alt="旅行网">
          </router-link>
        </div>

        <ul class="nav-list">
          <li>
            <router-link to="/flight" active-class="active">
              <i class="el-icon-location-information"></i>
              机票
            </router-link>
          </li>
          <li>
            <router-link to="/train" active-class="active">
              <i class="el-icon-truck"></i>
              火车票
            </router-link>
          </li>
          <li  v-if="userInfo && userInfo.type === 2">
            <router-link to="/manual-order" active-class="active">
              <i class="el-icon-edit-outline"></i>
              手动建单
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'TheHeader',
  data() {
    return {
      userInfo: null
    }
  },
  computed: {
    ...mapState(['currency', 'currencyList', 'currencyInfo']),
    ...mapGetters(['currencySymbol', 'currencyName']),
    currencies() {
      return this.currencyList
    },
    currentCurrencyName() {
      if (this.currencyInfo) {
        return this.currencyInfo.currency_name
      }
      return this.currencies[0]?.currency_name || '选择币种'
    }
  },
  async created() {
    // 从 localStorage 获取用户信息
    const userInfoStr = localStorage.getItem('userInfo')
    if (userInfoStr) {
      try {
        this.userInfo = JSON.parse(userInfoStr)
      } catch (error) {
        console.error('解析用户信息失败:', error)
      }
    }
  },
  methods: {
    ...mapMutations(['setCurrency']),
    
    isActiveCurrency(currency) {
      if (this.currencyInfo) {
        return currency.currency_standard_symbol === this.currencyInfo.currency_standard_symbol
      }
      return currency.currency_standard_symbol === this.currencies[0]?.currency_standard_symbol
    },
    
    handleCurrencyChange(currency) {
      this.setCurrency(currency)
      // 触发全局事件，通知其他组件币种已更改
      this.$bus.$emit('currencyChanged', currency)
    },
    handleCommand(command) {
      switch (command) {
        case 'profile':
          this.$router.push({
            path: '/OrderNew',
            query: { currentType: 'profile' }
          })
          break
        case 'orders':
          this.$router.push('/OrderNew')
          break
        case 'logout':
          this.logout()
          break
      }
    },
    logout() {
      // 清除用户信息和token
      localStorage.removeItem('userInfo')
      localStorage.removeItem('token')
      this.userInfo = null
      
      // 提示用户
      this.$message.success('已退出登录')
      
      // 如果当前在需要登录的页面，跳转到首页
      const requiresAuthPages = ['/profile', '/OrderNew']
      if (requiresAuthPages.includes(this.$route.path)) {
        this.$router.push('/')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0,0,0,0.05);
}

.header + * {
  padding-top: 96px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.header-top {
  background: #f8f9fa;
  border-bottom: 1px solid #eee;
  height: 36px;
  font-size: 13px;
  
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
}

.top-links, .user-actions {
  display: flex;
  align-items: center;
  gap: 8px;

  .link, .action-link {
    color: #666;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 0 8px;
    transition: all 0.3s ease;
    
    i {
      margin-right: 4px;
      font-size: 14px;
    }
    
    &:hover {
      color: #2681ff;
    }

    &.highlight {
      color: #2681ff;
      font-weight: 500;
    }
  }
}

.divider {
  width: 1px;
  height: 12px;
  background: #ddd;
  margin: 0 4px;
}

.lang-dropdown, .user-dropdown {
  .el-dropdown-link {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #666;
    padding: 0 8px;
    
    i {
      margin-right: 4px;
      &.el-icon--right {
        margin-right: 0;
        margin-left: 4px;
      }
    }
    
    &:hover {
      color: #2681ff;
    }
  }
}

.user-info {
  display: flex;
  align-items: center;
  gap: 8px;
  
  .avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #2681ff;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
  }
}

:deep(.el-dropdown-menu) {
  padding: 4px 0;
  
  .el-dropdown-menu__item {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    font-size: 13px;
    
    i {
      margin-right: 8px;
      font-size: 14px;
    }
    
    &:hover {
      background: #f0f7ff;
      color: #2681ff;
    }
    
    &.divided {
      border-top: 1px solid #ebeef5;
    }
  }
}

.nav-bar {
  background: #2681ff;
  height: 60px;
  
  .container {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.logo {
  margin-right: 40px;
  
  img {
    height: 80px;
    vertical-align: middle;
  }
}

.nav-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
  
  li {
    height: 100%;
    
    a {
      height: 100%;
      padding: 0 25px;
      color: rgba(255,255,255,0.9);
      text-decoration: none;
      font-size: 15px;
      display: flex;
      align-items: center;
      transition: all 0.3s ease;
      position: relative;
      
      i {
        margin-right: 6px;
        font-size: 16px;
      }
      
      &:hover, &.active {
        color: #fff;
        background: rgba(255,255,255,0.1);
        
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 3px;
          background: rgba(255,255,255,0.8);
          transform: scaleX(0.8);
          transition: transform 0.3s ease;
        }
      }
      
      &.active::after {
        transform: scaleX(1);
      }
    }
  }
}

// 响应式布局
@media screen and (max-width: 768px) {
  .header-top {
    display: none;
  }
  
  .header + * {
    padding-top: 50px;
  }
  
  .nav-bar {
    height: 50px;
    
    .container {
      padding: 0 15px;
    }
  }
  
  .logo {
    margin-right: 20px;
    
    img {
      height: 30px;
    }
  }
  
  .nav-list {
    li {
      a {
        padding: 0 15px;
        font-size: 14px;
      }
    }
  }
}

.currency-dropdown {
  .el-dropdown-link {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #666;
    padding: 0 8px;
    font-size: 13px;
    
    i {
      margin-right: 4px;
      &.el-icon--right {
        margin-right: 0;
        margin-left: 4px;
      }
    }
    
    &:hover {
      color: #2681ff;
    }
  }
}

.el-dropdown-menu__item {
  display: flex;
  align-items: center;
  gap: 8px;
  
  &.active {
    color: #409EFF;
    font-weight: 500;
    background: #f0f7ff;
    
    .el-icon-check {
      color: #409EFF;
    }
  }
  
  .el-icon-check {
    font-size: 14px;
  }
}
</style> 