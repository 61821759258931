<template>
  <div class="manual-order-page">
    <div class="page-header">
      <div class="header-content">
        <h2><i class="el-icon-edit"></i> 手动建单</h2>
      </div>
    </div>

    <div class="page-content">
      <el-form 
        :model="orderForm" 
        :rules="rules"
        ref="orderForm" 
        label-width="85px"
        class="order-form"
      >
        <!-- 航班信息 -->
        <div class="form-section flight-section">
          <div class="section-title">
            <span><i class="el-icon-plane"></i> 航班信息</span>
            <el-button 
              type="text" 
              @click="addFlight" 
              class="add-flight-btn"
            >
              <i class="el-icon-plus"></i> 添加航班
            </el-button>
          </div>

          <div class="flight-list">
            <div 
              v-for="(flight, index) in orderForm.flightList" 
              :key="index"
              class="flight-item"
            >
              <div class="flight-header">
                <span class="flight-title">
                  航段 {{index + 1}}
                </span>
                <el-button 
                  v-if="orderForm.flightList.length > 1"
                  type="text" 
                  @click="removeFlight(index)"
                  class="remove-flight-btn"
                >
                  <i class="el-icon-delete"></i> 删除航段
                </el-button>
              </div>

              <el-row :gutter="24">
                <el-col :span="6">
                  <el-form-item :label="'航空公司'">
                    <el-select
                      v-model="flight.airline"
                      filterable
                      placeholder="请选择航空公司"
                      :loading="loadingAirlines"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in airlineOptions"
                        :key="item.airline_code"
                        :label="`${item.airline_name}(${item.airline_code})`"
                        :value="item.airline_code"
                      >
                        <div class="airline-option">
                          <img 
                            :src="`https://static.tripcdn.com/packages/flight/airline-logo/latest/airline/48/${item.airline_code?.toUpperCase() || ''}.png`"
                            :alt="item.airline_name"
                            class="airline-logo"  style="width: 20px;"
                          >
                          <span class="airline-name">{{ item.airline_name }}</span>
                          <span class="airline-code">({{ item.airline_code }})</span>
                        </div>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item :label="'航班号'">
                    <el-input v-model="flight.flightNo"  @input="handleFlightNoInput(index)"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item :label="'客户'">
                    <el-select
                        v-model="clientId"
                        filterable
                        clearable
                        remote
                        reserve-keyword
                        :remote-method="handleClientSearch"
                        :loading="loadingClients"
                        @clear="handleClientClear"
                        placeholder="请绑定客户"
                        style="width: 100%"
                    >
                        <el-option
                        v-for="(client,index) in clientList"
                        :key="index"
                        :label="getClientLabel(client)"
                        :value="client.id"
                        >
                         <div class="client-info">
                           <span>{{ client.nickname || '未命名' }}</span>
                           <span class="contact-info">
                             {{ client.phone || client.email }}
                           </span>
                         </div>
                        </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item :label="'舱位等级'">
                    <el-select
                        v-model="flight.cabinType"
                        filterable
                        placeholder="请选择舱位等级"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="cabin in cabinOptions"
                            :key="cabin.value"
                            :label="cabin.label"
                            :value="cabin.value"
                        >
                        </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="24">
                <el-col :span="6">
                  <el-form-item :label="'出发城市'">
                    <el-select
                      style="width: 100%"
                      v-model="flight.depAir"
                      filterable
                      remote
                      reserve-keyword
                      placeholder="请输入城市名或机场代码"
                      :remote-method="queryAirports"
                      :loading="loading"
                      @change="(val) => handleAirportChange(val, index, 'departure')"
                    >
                      <el-option
                        v-for="item in airportOptions"
                        :key="item.airport_code_3"
                        :label="`${item.city_name}-${item.airport_name}(${item.airport_code_3})`"
                        :value="item.airport_code_3"
                      >
                        <span>{{ item.city_name }}-{{ item.airport_name }}</span>
                        <span class="airport-code">({{ item.airport_code_3 }})</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item :label="'到达城市'">
                    <el-select
                      style="width: 100%"
                      v-model="flight.desAir"
                      filterable
                      remote
                      reserve-keyword
                      placeholder="请输入城市名或机场代码"
                      :remote-method="queryAirports"
                      :loading="loading"
                      @change="(val) => handleAirportChange(val, index, 'arrival')"
                    >
                    <el-option
                        v-for="item in airportOptions"
                        :key="item.airport_code_3"
                        :label="`${item.city_name}-${item.airport_name}(${item.airport_code_3})`"
                        :value="item.airport_code_3"
                      >
                        <span>{{ item.city_name }}-{{ item.airport_name }}</span>
                        <span class="airport-code">({{ item.airport_code_3 }})</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item :label="'起飞时间'">
                    <el-date-picker
                      v-model="flight.departTime"
                      type="datetime"
                      placeholder="选择起飞时间"
                      style="width: 100%"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item :label="'到达时间'">
                    <el-date-picker
                      v-model="flight.destTime"
                      type="datetime"
                      placeholder="选择到达时间"
                      style="width: 100%"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="24">
                <el-col :span="6">
                  <el-form-item :label="'供应商'">
                    <el-select 
                      v-model="supplierId" 
                      placeholder="选择供应商"
                      @change="handleSupplierChange"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in supplierList"
                        :key="item.id"
                        :label="item.supplier_name"
                        :value="item.id"
                      >
                        <span>{{ item.supplier_name }}</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                 <el-form-item :label="'供应商币种'">
                    <el-select 
                      v-model="currencyId"
                      placeholder="选择币种"
                      style="width: 100%"
                      :disabled="!supplierId"
                    >
                      <el-option
                        v-for="currency in availableCurrencies"
                        :key="currency.id"
                        :label="currency.currency_name"
                        :value="currency.id"
                      >
                        <div class="currency-option">
                          <span>{{ currency.currency_name }}</span>
                          <!-- <span class="currency-info">
                            <span class="currency-symbol">{{ currency.currency_symbol }}</span>
                            <span class="exchange-rate" v-if="currency.exchange_rate !== '1.0000'">
                              (汇率: {{ currency.exchange_rate }})
                            </span>
                          </span> -->
                        </div>
                      </el-option>
                    </el-select>
                  </el-form-item>   
                </el-col>
                <el-col :span="6">
                  <el-form-item :label="'供应商成本'">
                    <el-input 
                      v-model="flight.cost"
                      :min="0"
                      :precision="2"
                      :step="100"
                      style="width: 100%"
                      placeholder="请输入供应商成本"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item :label="'售价'">
                    <el-input 
                      v-model="flight.ticketPrice"
                      :min="0"
                      :precision="2"
                      :step="100"
                      style="width: 100%"
                      placeholder="请输入售价"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="6">
                  <el-form-item :label="'机票类型'">
                    <el-select 
                    v-model="orderForm.orderInfo.productSubType" 
                    placeholder="机票类型" 
                    style="width: 100%"
                    >
                    <el-option label="国际" value="1"></el-option>
                    <el-option label="国内" value="0"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="订单状态">
                        <el-select 
                            v-model="orderForm.orderInfo.status"
                            placeholder="请选择订单状态"
                            style="width: 100%"
                        >
                            <el-option
                            v-for="item in getStatusOptions()"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            >
                            </el-option>
                        </el-select>
                        </el-form-item>
                </el-col>
              </el-row>
              
            </div>
          </div>
          <!-- 总价展示 -->
          <div class="total-price-section">
            <span class="label">订单总价：</span>
            <span class="amount">
              {{ currencyInfo?.currency_symbol }} 
              {{ (calculateTotalPrice  * currencyInfo?.exchange_rate).toFixed(2) }}
            </span>
          </div>
        </div>

        <!-- 国际乘客信息 -->
        <div class="form-section passenger-section" v-if="orderForm.orderInfo.productSubType==1">
          <div class="section-title">
            <span><i class="el-icon-user"></i> 乘客信息</span>
            <el-button 
                type="text" 
                @click="addUser" 
                class="add-user-btn"
              >
                <i class="el-icon-plus"></i> 添加乘客
              </el-button>
          </div>
          
          <div v-for="(passenger, index) in orderForm.passengerList" 
               :key="index" 
               class="passenger-item">
             <div class="passenger-header">
               <span class="passenger-title">乘客 {{index + 1}}</span>
               <div class="passenger-actions">
                 <el-button 
                   type="text" 
                   @click="showFrequentPassengers(index, passenger.passengerType)"
                   class="select-frequent-btn"
                 >
                   <i class="el-icon-user"></i> 选择常用乘机人
                 </el-button>
                 <el-button 
                   v-if="orderForm.passengerList.length > 1"
                   type="text" 
                   @click="removePassenger(index)"
                   class="remove-passenger-btn"
                 >
                   <i class="el-icon-delete"></i> 删除乘客
                 </el-button>
               </div>
             </div>

             <el-row :gutter="24">
               <el-col :span="8">
                 <el-form-item :label="'姓氏'" :prop="`passengerList.${index}.sur`">
                   <el-input v-model="passenger.sur" placeholder="请输入乘客姓氏"></el-input>
                 </el-form-item>
               </el-col>
               <el-col :span="8">
                 <el-form-item :label="'名字'" :prop="`passengerList.${index}.name`">
                   <el-input v-model="passenger.name" placeholder="请输入乘客名字"></el-input>
                 </el-form-item>
               </el-col>
               <el-col :span="8">
                 <el-form-item label="性别">
                   <el-select 
                       v-model="passenger.gender" 
                       placeholder="性别" 
                       style="width: 100%"
                       >
                       <el-option label="男" value="1"></el-option>
                       <el-option label="女" value="0"></el-option>
                       </el-select>
                 </el-form-item>
               </el-col>
             </el-row>
             
             <el-row :gutter="24">
               <el-col :span="8">
                   <el-form-item label="乘客类型" prop="passengerType">
                       <el-select 
                       v-model="passenger.passengerType" 
                       placeholder="乘客类型" 
                       style="width: 100%"
                       >
                          <el-option label="成人" value="0"></el-option>
                           <el-option label="儿童" value="1"></el-option>
                           <el-option label="婴儿" value="2"></el-option>
                           <el-option label="老人" value="3"></el-option>
                           <el-option label="学生" value="4"></el-option>
                           <el-option label="劳务" value="5"></el-option>
                           <el-option label="移民" value="6"></el-option>
                           <el-option label="海员" value="7"></el-option>
                           <el-option label="青年" value="8"></el-option>
                       </el-select>
                   </el-form-item>
               </el-col>
               <el-col :span="8">
                   <el-form-item label="国籍" prop="nationality">
                           <el-select v-model="passenger.nationality" filterable style="width: 100%">
                             <el-option
                               v-for="item in countries"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                             </el-option>
                           </el-select>
                         </el-form-item>
               </el-col>
               <el-col :span="8">
                 <el-form-item label="证件号码">
                   <el-input v-model="passenger.IDNumber" placeholder="请输入证件号码"></el-input>
                 </el-form-item>
               </el-col>
             </el-row>

             <el-row :gutter="24">
               <el-col :span="8">
                   <el-form-item label="手机号" prop="phone">
                       <el-input v-model="passenger.phone" placeholder="请输入手机号">
                         <el-select 
                           v-model="passenger.phoneCode" 
                           slot="prepend" 
                           style="width: 100px;"
                           filterable
                         >
                           <el-option
                             v-for="prefix in phonePrefixes"
                             :key="prefix.value"
                             :label="prefix.label"
                             :value="prefix.value"
                           >
                           </el-option>
                         </el-select>
                       </el-input>
                     </el-form-item>
               </el-col>
               <el-col :span="8">
                 <el-form-item label="出生日期">
                   <el-date-picker
                     v-model="passenger.birthday"
                     type="date"
                     placeholder="选择出生日期"
                     style="width: 100%"
                   ></el-date-picker>
                 </el-form-item>
               </el-col>
               <el-col :span="8">
                 <el-form-item label="证件有效期">
                   <el-date-picker
                     v-model="passenger.IDExpiration"
                     type="date"
                     placeholder="选择证件有效期"
                     style="width: 100%"
                   ></el-date-picker>
                 </el-form-item>
               </el-col>
             </el-row>
           </div>
         </div>

        <!-- 国内乘客信息 -->
        <div class="form-section passenger-section" v-if="orderForm.orderInfo.productSubType==0">
          <div class="section-title">
            <span><i class="el-icon-user"></i> 乘客信息</span>
            <el-button 
                type="text" 
                @click="addUser" 
                class="add-user-btn"
              >
                <i class="el-icon-plus"></i> 添加乘客
              </el-button>
          </div>
          
          <div v-for="(passenger, index) in orderForm.passengerList" 
               :key="index" 
               class="passenger-item">
             <div class="passenger-header">
               <span class="passenger-title">乘客 {{index + 1}}</span>
               <div class="passenger-actions">
                 <el-button 
                   type="text" 
                   @click="showFrequentPassengers(index, passenger.passengerType)"
                   class="select-frequent-btn"
                 >
                   <i class="el-icon-user"></i> 选择常用乘机人
                 </el-button>
                 <el-button 
                   v-if="orderForm.passengerList.length > 1"
                   type="text" 
                   @click="removePassenger(index)"
                   class="remove-passenger-btn"
                 >
                   <i class="el-icon-delete"></i> 删除乘客
                 </el-button>
               </div>
             </div>

             <el-row :gutter="24">
               <el-col :span="6">
                 <el-form-item :label="'姓名'" :prop="`passengerList.${index}.name`">
                   <el-input v-model="passenger.name" placeholder="请输入乘客姓名"></el-input>
                 </el-form-item>
               </el-col>
               <el-col :span="6">
                   <el-form-item label="乘客类型" prop="passengerType">
                       <el-select 
                       v-model="passenger.passengerType" 
                       placeholder="乘客类型" 
                       style="width: 100%"
                       >
                          <el-option label="成人" value="0"></el-option>
                           <el-option label="儿童" value="1"></el-option>
                           <el-option label="婴儿" value="2"></el-option>
                           <el-option label="老人" value="3"></el-option>
                           <el-option label="学生" value="4"></el-option>
                           <el-option label="劳务" value="5"></el-option>
                           <el-option label="移民" value="6"></el-option>
                           <el-option label="海员" value="7"></el-option>
                           <el-option label="青年" value="8"></el-option>
                       </el-select>
                   </el-form-item>
               </el-col>
               <el-col :span="6">
                 <el-form-item label="证件号码">
                   <el-input v-model="passenger.IDNumber" placeholder="请输入证件号码"></el-input>
                 </el-form-item>
               </el-col>
               <el-col :span="6">
                   <el-form-item label="手机号" prop="phone">
                       <el-input v-model="passenger.phone" placeholder="请输入手机号">
                         <el-select 
                           v-model="passenger.phoneCode" 
                           slot="prepend" 
                           style="width: 100px;"
                           filterable
                         >
                          <el-option label="+86" value="+86"></el-option>
                         </el-select>
                       </el-input>
                     </el-form-item>
               </el-col>
             </el-row>
             
           </div>
         </div>

        <!-- 联系人信息 -->
        <div class="form-section">
          <div class="section-title">
            <i class="el-icon-phone"></i>
            联系人信息
          </div>
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="联系人" prop="contact.linker">
                <el-input v-model="orderForm.contact.linker" placeholder="请输入联系人"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="手机号" prop="contact.phone">
                <el-input v-model="orderForm.contact.phone" placeholder="请输入手机号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="邮箱" prop="contact.email">
                <el-input v-model="orderForm.contact.email" placeholder="请输入邮箱"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

        <!-- 提交按钮 -->
        <div class="form-actions">
          <el-button type="primary" size="medium" @click="submitForm">提交订单</el-button>
          <el-button size="medium" @click="resetForm">重置</el-button>
        </div>
      </el-form>
    </div>
     <!-- 常用乘机人选择弹窗 -->
     <el-dialog
      title="选择常用乘机人"
      :visible.sync="showFrequentDialog"
      width="800px"
      :close-on-click-modal="false"
      custom-class="frequent-passenger-dialog"
    >
      <div class="frequent-passengers">
        <el-table
          :data="frequentPassengers"
          style="width: 100%"
          @row-click="selectPassenger"
          highlight-current-row
          :header-cell-style="{background:'#f5f7fa', color: '#606266'}"
        >
          <el-table-column align="center" label="姓名" min-width="150">
            <template slot-scope="scope">
              <span class="passenger-name">
                <span class="surname">{{ scope.row.sur }}</span>
                <span class="separator">/</span>
                <span class="firstname">{{ scope.row.name }}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="证件号码" min-width="180">
            <template slot-scope="scope">
              <span class="id-number">{{ scope.row.IDNumber }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="性别" width="80">
            <template slot-scope="scope">
              <el-tag size="mini" :type="scope.row.gender === '1' ? 'primary' : 'success'">
                {{ scope.row.gender === '1' ? '男' : '女' }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="手机号码" min-width="150">
            <template slot-scope="scope">
              <span class="phone-number">
                <i class="el-icon-mobile-phone"></i>
                {{ scope.row.phone }}
              </span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="100" align="center">
            <template slot-scope="scope">
              <el-button 
                @click.stop="selectPassenger(scope.row, currentPassengerIndex)" 
                type="primary" 
                size="mini" 
                plain
              >
                选择
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showFrequentDialog = false" size="small">取 消</el-button>
      </div>
     </el-dialog>
  </div>


</template>

<script>
import { getAirPort, Getairlines, supplier } from '@/api/flight'
import { getCountryCode,getClientSearch,getPassengerList } from '@/api/user'
import { createOrder } from '@/api/order'
import { mapState } from 'vuex'

export default {
  name: 'ManualOrder',
  data() {
    return {
      currentPassengerIndex: null,
      currentPassengerType: '',
      orderForm: {
        orderInfo: {
          status: 10,
          productSubType: "1",
          groupTag: false,
        },
        flightList: [{
          airline: '',
          flightNo: '',
          cabinType:'',
          departureCityName: '',
          destinationCityName: '',
          departTime: '',
          destTime: '',
          depAir: '',
          desAir: '',
          depAirCN: '',
          desAirCN: '',
          depTerm: '',
          desTerm: ''
        }],
        passengerList: [{
          name: '',
          sur: '',
          passengerType: '0',
          nationality: 'CN',
          IDType: '0',
          IDNumber: '',
          gender: '1',
          birthday: '',
          phone: '',
          phoneCode: '+86',
          IDExpiration:''
        }],
        contact: {
          linker: '',
          phone: '',
          email: '',
          isSMS: true,
          isEmail: true
        },
      },
      rules: {
        'flightList.0.airline': [{ required: true, message: '请输入航空公司', trigger: 'blur' }],
        'flightList.0.flightNo': [{ required: true, message: '请输入航班号', trigger: 'blur' }],
        passengerList: [{ required: true, message: '请输入乘客信息', trigger: 'blur' }],
        contact: [{ required: true, message: '请输入联系人信息', trigger: 'blur' }]
      },
      submitting: false,
      airportOptions: [],
      loading: false,
      airlineOptions: [],
      loadingAirlines: false,
      supplierId: '',
      currencyId: '',
      supplierList: [],
      availableCurrencies: [],
      cabinOptions: [
        { label: '经济舱', value: '0' },
        { label: '豪华经济舱', value: '1' },
        { label: '商务舱', value: '3' },
        { label: '头等舱', value: '4' }
      ],
      countries: [],
      phonePrefixes: [],
      clientList:[],
      clientId:'',
      frequentPassengersVisible: false,
      frequentPassengers: [],
      selectedFrequentPassengers: [],
      showFrequentDialog: false,
      loadingClients: false,
    }
  },
  computed: {
    ...mapState(['currencyInfo']),
    calculateTotalPrice() {
      return this.orderForm.flightList.reduce((total, flight) => {
        return total + (Number(flight.ticketPrice) || 0) +(Number(flight.cost) || 0)
      }, 0).toFixed(2)
    },
  },
  created() {
      this.loadAirlines()
      this.supplier()
      this.fetchCountries()
  },
  methods: {
    selectPassenger(passenger, index) {
      const newPassenger = {
        ...this.orderForm.passengerList[index],
        name: passenger.name || '',
        sur: passenger.sur || '',
        gender: passenger.gender || '1',
        passengerType: passenger.passengerType || '0',
        nationality: passenger.nationality || 'CN',
        IDType: passenger.IDType || '0',
        IDNumber: passenger.IDNumber || '',
        phone: passenger.phone || '',
        phoneCode: passenger.phoneCode || '+86',
        birthday: passenger.birthday || '',
        IDExpiration: passenger.IDExpiration || ''
      }

      this.$set(this.orderForm.passengerList, index, newPassenger)
      
      console.log("passenger==>  ", this.orderForm.passengerList[index]);
      this.showFrequentDialog = false
    },
    handleFlightNoInput(index) {
      const flight = this.orderForm.flightList[index];
      if (flight.flightNo) {
        const airlineCode = flight.flightNo.substring(0, 2).toUpperCase(); // 假设航司码是航班号的前两个字符
        const airlineOption = this.airlineOptions.find(option => option.airline_code === airlineCode);
        if (airlineOption) {
          flight.airline = airlineCode;
        } else {
          flight.airline = ''; // 如果没有匹配的航空公司，清空航空公司选择
        }
      } else {
        flight.airline = ''; // 如果航班号为空，清空航空公司选择
      }
    },
    // 获取可选状态列表
    getStatusOptions() {
      return [
        { value: 0, label: '已取消' },
        { value: 1, label: '待询价' },
        { value: 2, label: '处理中' },
        { value: 3, label: '处理中' },
        { value: 4, label: '待支付' },
        { value: 5, label: '待出票' },
        { value: 6, label: '已出票' },
        { value: 7, label: '待确认价格' },
        { value: 8, label: '已支付' },
        { value: 10, label: '人工' },
        { value: 11, label: '审核中' }
      ]
    },
    // 获取国家代码和区号列表
    async fetchCountries() {
      try {
        const res = await getCountryCode()
        if (res.code === 1 && res.data && res.data.countrys) {
          // 处理国家列表
          this.countries = res.data.countrys.map(country => ({
            label: country.country_name_zh,
            value: country.country_two_letter_code
          }))
          
          // 处理电话区号列表
          const uniquePrefixes = new Map()
          res.data.countrys.forEach(country => {
            const prefix = country.phone_prefix
            if (prefix) {
              uniquePrefixes.set(prefix, {
                label: `${prefix}`,
                value: `${prefix}`
              })
            }
          })
          
          // 转换为数组并排序
          this.phonePrefixes = Array.from(uniquePrefixes.values())
        } else {
          console.error('获取失败:', res.message)
          this.$message.error('获取失败')
        }
      } catch (error) {
        console.error('获取出错:', error)
        this.$message.error('获取失败')
      }
    },
    // 加载客户列表
    async handleClientSearch(query) {
      if (query.length < 2) return
      
      this.loadingClients = true
      try {
        const res = await getClientSearch({
          search: query
        })
        if (res.code === 1) {
          this.clientList = res.data || []
        }
      } catch (error) {
        console.error('获取客户列表失败:', error)
        this.$message.error('获取客户列表失败')
      } finally {
        this.loadingClients = false
      }
    },
    // 处理清空选择
    handleClientClear() {
      this.clientId = ''
      this.clientList = []
    },
    async submitForm() {
        try {
          this.submitting = true
          // 构造请求参数
            const requestData = {
            supplierId: this.supplierId,
            currenices:this.currencyId,
            contact_linker: this.orderForm.contact.linker,
            contact_phone: this.orderForm.contact.phone,
            contact_email: this.orderForm.contact.email,
            status: this.orderForm.orderInfo.status,
            saleTotal: this.calculateTotalPrice,
            productSubType: this.orderForm.orderInfo.productSubType,
            order_json: {
                passengerList: this.orderForm.passengerList,
                flightList:this.orderForm.flightList
            },
          };
          // TODO: 调用创建订单API
          const res = await createOrder(requestData);
          if (res.code === 1) {
              this.$message.success('订单创建成功')
            // 跳转到订单详情页
            this.$router.push({
                path: '/order/detail/' + res.data,
                query: {
                  orderId: res.data
                }
              })
          } else {
            this.$message.error('订单创建失败')
          }
          this.resetForm()
        } catch (error) {
          this.$message.error(error.message || '创建订单失败')
        } finally {
          this.submitting = false
        }
    },
    resetForm() {
      this.$refs.orderForm.resetFields()
    },
    addFlight() {
      this.orderForm.flightList.push({
        airline: '',
        flightNo: '',
        depAir: '',
        desAir: '',
        departTime: '',
        destTime: '',
        ticketPrice: 0
      })
    },
    addUser() {
      // 创建新乘客对象
      const newPassenger = {
        name: '',
        gender: '1',
        passengerType: '0',
        nationality: 'CN',
        IDType: '0',
        IDNumber: '',
        phone: '',
        phoneCode: '+86',
      }
      
      // 如果是国际航班，添加额外的必要字段
      if (this.orderForm.orderInfo.productSubType === '1') {
        Object.assign(newPassenger, {
          sur: '',
          name: '',
          birthday: '',
          IDExpiration: '',
        })
      }
      
      // 添加到乘客列表
      this.orderForm.passengerList.push(newPassenger)
    },
    removeFlight(index) {
      this.$confirm('确定要删除该航班吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.orderForm.flightList.splice(index, 1)
        this.$message.success('删除成功')
      }).catch(() => {})
    },
    removePassenger(index) {
      this.$confirm('确定要删除该乘客吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.orderForm.passengerList.splice(index, 1)
        this.$message.success('删除成功')
      }).catch(() => {})
    },
    async queryAirports(query) {
        if (query && query.length >= 1) {
        try {
          this.loading = true
          const res = await getAirPort({ 
            search: query,
            size: 20  // 限制返回数量
          })
          if (res.code === 1) {
            this.airportOptions = res.data || []
          }
        } catch (error) {
          console.error('获取机场列表失败:', error)
          this.$message.error('获取机场列表失败')
        } finally {
          this.loading = false
        }
      } else {
        this.airportOptions = []
      }
    },
    handleAirportChange(code, index, type) {
      const airport = this.airportOptions.find(item => item.airport_code_3 === code)
      if (!airport) return
      
      if (type === 'departure') {
        this.orderForm.flightList[index].departureCityName = airport.city_name
        this.orderForm.flightList[index].depAirCN = airport.airport_name
        this.orderForm.flightList[index].depAir = airport.airport_code_3
      } else {
        this.orderForm.flightList[index].destinationCityName = airport.city_name
        this.orderForm.flightList[index].desAirCN = airport.airport_name
        this.orderForm.flightList[index].desAir = airport.airport_code_3
      }
    },
    async loadAirlines() {
      try {
        this.loadingAirlines = true
        const res = await Getairlines()
        if (res.code === 1) {
          this.airlineOptions = (res.data || []).map(item => ({
            airline_code: item.iata_code,
            airline_name: item.airline_chinese
          }))
        }
      } catch (error) {
        console.error('获取航空公司列表失败:', error)
        this.$message.error('获取航空公司列表失败')
      } finally {
        this.loadingAirlines = false
      }
    },
    async supplier() {
        try {
        const res = await supplier()
        if (res.code === 1) {
            this.supplierList = (res.data || []).map(item => {
              return {
                id: item.id,
                supplier_name: item.supplier_name,
                currency_ids: item.currency_id || [],
                currencyList: Array.isArray(item.currencyList) ? item.currencyList : []
              }
            })
        }
      } catch (error) {
        this.$message.error('获取供应商列表失败')
      }
    },
    // 显示常用乘机人列表
    async showFrequentPassengers(index, type) {
      try {
        this.currentPassengerIndex = index
        this.currentPassengerType = type
        const res = await getPassengerList()
        if (res.code === 1 && res.data && res.data.list) {
          this.frequentPassengers = res.data.list
          this.showFrequentDialog = true
        } else {
          throw new Error(res.message || '获取常用乘机人失败')
        }
      } catch (error) {
        console.error('获取常用乘机人失败:', error)
        this.$message.error(error.message || '获取失败，请重试')
      }
    },
    // 处理常用乘机人选择变化
    handleFrequentPassengerSelection(selection) {
      this.selectedFrequentPassengers = selection
    },
    // 添加选中的常用乘机人
    addSelectedPassengers() {
      this.selectedFrequentPassengers.forEach(passenger => {
        const newPassenger = { ...passenger }
        if (this.orderForm.orderInfo.productSubType === '1') {
          // 国际航班需要的额外字段
          if (!newPassenger.sur || !newPassenger.name) {
            const [sur, ...givenNames] = newPassenger.name.split(' ')
            newPassenger.sur = sur
            newPassenger.name = givenNames.join(' ')
          }
        }
        this.orderForm.passengerList.push(newPassenger)
      })
      this.frequentPassengersVisible = false
      this.$message.success(`已添加 ${this.selectedFrequentPassengers.length} 位乘客`)
    },
    // 获取乘客类型标签
    getPassengerTypeLabel(type) {
      const types = {
        '0': '成人',
        '1': '儿童',
        '2': '婴儿',
        '3': '老人',
        '4': '学生',
        '5': '劳务',
        '6': '移民',
        '7': '海员',
        '8': '青年'
      }
      return types[type] || '未知'
    },
    // 处理供应商变化
    handleSupplierChange(supplierId) {
      const supplier = this.supplierList.find(s => s.id === supplierId)
      if (supplier) {
        // 更新可用币种列表
        this.availableCurrencies = supplier.currencyList
        // 默认选择第一个币种
        if (this.availableCurrencies.length > 0) {
          this.currencyId = this.availableCurrencies[0].id
        } else {
          this.currencyId = ''
        }
      } else {
        this.availableCurrencies = []
        this.currencyId = ''
      }
    },
    // 获取客户显示标签
    getClientLabel(client) {
      const name = client.nickname || ''
      const contact = client.phone || client.email
      return `${name} (${contact})`
    },
  }
}
</script>

<style lang="scss" scoped>
.manual-order-page {
  padding: 80px 50px;
  background-color: #f5f7fa;
  min-height: calc(100vh - 120px);

  .page-header {
    margin-bottom: 16px;
    
    .header-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
    h2 {
      font-size: 20px;
      font-weight: 500;
      color: #303133;
      margin: 0;
      
      i {
        margin-right: 8px;
        font-size: 20px;
      }
    }
  }

  .page-content {
    background: #fff;
    border-radius: 8px;
    padding: 40px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.05);
  }

  .form-section {
    margin-bottom: 40px;
    
    .section-title {
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      color: #303133;
      margin-bottom: 24px;
      padding-bottom: 12px;
      border-bottom: 1px solid #EBEEF5;
      
      i {
        margin-right: 8px;
        color: #409EFF;
      }
    }
  }

  .total-price {
    font-size: 18px;
    font-weight: bold;
    color: #F56C6C;
  }

  .form-actions {
    margin-top: 40px;
    text-align: center;
    
    .el-button {
      min-width: 120px;
      
      & + .el-button {
        margin-left: 16px;
      }
    }
  }

  .flight-item {
    margin-bottom: 24px;
    padding: 32px;
    border: 1px solid #EBEEF5;
    border-radius: 4px;
    background-color: #fcfcfc;
    transition: all 0.3s;
    
    &:hover {
      box-shadow: 0 2px 12px 0 rgba(0,0,0,.05);
    }

    .flight-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;

      .flight-title {
        font-size: 15px;
        font-weight: 500;
        color: #303133;
      }

      .remove-flight-btn {
        color: #F56C6C;
        font-size: 13px;
        
        i {
          margin-right: 4px;
        }
        
        &:hover {
          color: #f78989;
        }
      }
    }

    .el-row {
      margin-bottom: 16px;
      
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .add-flight-btn {
    float: right;
    padding: 0;
    font-size: 13px;
    
    i {
      margin-right: 4px;
    }
  }

  .add-user-btn {
    float: right;
    padding: 0;
    font-size: 13px;
    
    i {
      margin-right: 4px;
    }
  }

  .airport-code {
    float: right;
    color: #909399;
    font-size: 13px;
  }

  .airline-code {
    float: right;
    color: #909399;
    font-size: 13px;
  }

  .airline-option {
    display: flex;
    align-items: center;
    
    .airline-logo {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      object-fit: contain;
    }

    .airline-name {
      flex: 1;
    }

    .airline-code {
      float: right;
      color: #909399;
      font-size: 13px;
      margin-left: 8px;
    }
  }

  .total-price-section {
    margin-top: 24px;
    text-align: right;
    padding: 16px 32px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #EBEEF5;

    .label {
      font-size: 16px;
      color: #606266;
      margin-right: 16px;
    }

    .amount {
      font-size: 20px;
      font-weight: 500;
      color: #F56C6C;
    }
  }

  // 调整 input-number 组件样式
  .el-input-number {
    .el-input__inner {
      text-align: left;
      padding-left: 12px;
    }
  }

  .passenger-item {
    margin-bottom: 24px;
    padding: 24px;
    background: #fcfcfc;
    border: 1px solid #EBEEF5;
    border-radius: 4px;
    transition: all 0.3s;
    
    &:hover {
      box-shadow: 0 2px 12px 0 rgba(0,0,0,.05);
    }
    
    &:last-child {
      margin-bottom: 0;
    }
    
    .passenger-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      padding-bottom: 12px;
      border-bottom: 1px solid #EBEEF5;
      
      .passenger-title {
        font-size: 15px;
        font-weight: 500;
        color: #303133;
      }
      
      .passenger-actions {
        display: flex;
        align-items: center;
        gap: 16px;  // 按钮之间的间距
      }
      
      .select-frequent-btn {
        display: flex;
        align-items: center;
        color: #409EFF;
        
        i {
          margin-right: 4px;
        }
        
        &:hover {
          color: #66b1ff;
        }
      }
      
      .remove-passenger-btn {
        color: #F56C6C;
        font-size: 13px;
        
        i {
          margin-right: 4px;
        }
        
        &:hover {
          color: #f78989;
        }
      }
    }
  }

  .add-user-btn {
    display: flex;
    align-items: center;
    
    &.is-disabled {
      color: #C0C4CC;
      cursor: not-allowed;
    }
    
    .limit-tip {
      margin-left: 4px;
      font-size: 12px;
      color: #909399;
    }
  }

  .select-frequent-btn {
    display: flex;
    align-items: center;
    
    i {
      margin-right: 4px;
    }
  }

  .frequent-passengers-list {
    .el-table {
      margin: -16px;
    }
  }

  .client-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .contact-info {
      color: #909399;
      font-size: 13px;
    }
  }
}
</style> 