import { httpPost } from './http'

// 获取个人信息
export function getUserInfo() {
  return httpPost('/center/get')
}

// 获取订单列表
export function getOrderList(params) {
  return httpPost('/center/order', params)
} 

//获取国籍/区号/币种
export function getCountryCode() {
  return httpPost('/config/config')
}

// 修改个人信息
export function setUserInfo(params) {
  return httpPost('/center/set', params)
}

// 新增/编辑乘客
export function addPassenger(params) {
  return httpPost('/center/addPassenger', params)
}

// 获取常用乘机人
export function getPassengerList(params) {
  return httpPost('/center/passenger', params)
}

//查看乘机人详情
export function getpassengerInfo(params) {
  return httpPost('/center/passengerInfo', params)
}


// 删除常用乘机人
export function deletePassenger(params) {
  return httpPost('/center/delPassenger', params)
}

//获取所有客户
export function getClient(params) {
  return httpPost('/center/getClient', params)
}

//所有客户-动态搜索用
export function getClientSearch(params) {
  return httpPost('/center/getClientSearch', params)
}

