import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import { getCountryCode } from '@/api/user'
export default new Vuex.Store({
  state: {
    currency: '',
    currencyInfo: null,
    currencyList: [],
  },
  getters: {
    currencySymbol: state => {
      return state.currencyInfo?.currency_symbol
    },
    currencyName: state => {
      return state.currencyInfo?.currency_name
    },
    exchangeRate: state => {
      return state.currencyInfo?.exchange_rate
    }
  },
  mutations: {
    setCurrency(state, currency) {
      if (!state.currencyList.length) {
        return
      }
      state.currency = currency
      localStorage.setItem('currency', currency)
      state.currencyInfo = state.currencyList.find(
        c => c.currency_standard_symbol === currency
      )
    },
    setCurrencyList(state, list) {
      state.currencyList = list
      if (list.length > 0) {
        const defaultCurrency = list[0].currency_standard_symbol
        state.currency = defaultCurrency
        state.currencyInfo = list[0]
        localStorage.setItem('currency', defaultCurrency)
      }
    }
  },
  actions: {
    initCurrency({ commit }) {
      const savedCurrency = localStorage.getItem('currency')
      if (savedCurrency) {
        commit('setCurrency', savedCurrency)
      }
    },
    async initCurrencyList({ commit }) {
      try {
        const res = await getCountryCode()
        if (res.code === 1 && res.data?.currencies) {
          const enabledCurrencies = res.data.currencies.filter(c => c.is_enabled === 1)
          if (enabledCurrencies.length > 0) {
            commit('setCurrencyList', enabledCurrencies)
            Vue.prototype.$bus.$emit('currencyListInitialized')
          } else {
            console.warn('没有可用的币种')
          }
        }
      } catch (error) {
        console.error('获取币种列表失败:', error)
      }
    }
  },
  modules: {
  }
}) 